<script setup lang="ts">
type EndorsementCardProps = {
  quote: string;
  author: string;
  authorRole: string;
};
defineProps<EndorsementCardProps>();
</script>

<template>
  <div class="EndorsementCard-card padd-m border-radius-8">
    <div>
      <p class="text-bold"> {{ quote }} </p>
    </div>
    <div class="margin-top-l">
      <div class="flexContainer flexContainer-alignStart">
        <div class="flexContainer flexContainer-column">
          <p class="text-bold margin-bottom-xxs"> {{ author }} </p>
          <p class="text-small margin-bottom-xxs"> {{ authorRole }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.EndorsementCard-card {
  width: 300px;
  background-color: var(--kogPlatformWhite);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
}
</style>
