<script setup lang="ts">
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';

type Props = {
  title: string;
  subtitle?: string;
  loading?: boolean;
  progress?: number;
};
defineProps<Props>();
</script>

<template>
  <div class="ActivationTemplate--container">
    <div class="ActivationTemplate--container container-wideWidth">
      <div class="kog-row padd-top-l">
        <div class="kog-col-lg-6 kog-col-12">
          <div class="ActivationTemplate--main-area">
            <kog-progress-bar
              v-if="progress !== undefined"
              class="margin-top-s margin-bottom-s"
              :progress="progress"
              size="s"
              type="primary"
            />
            <div class="FadeIn">
              <h1 class="heading-l margin-top-m margin-bottom-xs">{{ title }}</h1>
              <p v-if="subtitle">{{ subtitle }}</p>
              <kog-loader :loading="loading">
                <slot name="action" />
              </kog-loader>
            </div>
          </div>
        </div>
        <div class="ActivationTemplate--extra-area-container kog-col-6">
          <div class="ActivationTemplate--extra-area height-full FadeIn">
            <slot name="extra" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ActivationTemplate--container {
  min-height: calc(100vh - var(--full-page-navbar-height));
  background: linear-gradient(90deg, var(--kog-colors-white) 50%, var(--kog-colors-light-400) 50%);
}

.ActivationTemplate--main-area {
  margin: 0 var(--space-xxxl);
}

.FadeIn {
  animation: fadeIn 0.3s;
}

@media (--viewport-m) {
  .ActivationTemplate--extra-area-container {
    display: none;
  }

  .ActivationTemplate--container {
    background: var(--kog-colors-white);
  }

  .ActivationTemplate--main-area {
    margin: 0 var(--space-m);
  }
}
</style>
