<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

const MIN_PASSWORD_LENGTH = 10;

const model = defineModel<string | undefined>();

const props = defineProps<{
  enterPasswordLabel?: string;
}>();

const password = ref('');
const passwordConfirmation = ref('');
const showRuleErrors = ref(false);
const showPasswordDoesNotMatchError = ref(false);

const doPasswordsMatch = computed(() => password.value === passwordConfirmation.value);
const hasLowerCase = computed(() => password.value !== password.value.toUpperCase());
const hasUpperCase = computed(() => password.value !== password.value.toLowerCase());
const hasNumber = computed(() => /\d/.test(password.value));
const isLongEnough = computed(() => password.value.length >= MIN_PASSWORD_LENGTH);
const enterPasswordLabel = props.enterPasswordLabel || 'Enter password';

const validators = [
  {
    message: 'One lowercase letter',
    valid: hasLowerCase,
  },
  {
    message: 'One uppercase letter',
    valid: hasUpperCase,
  },
  {
    message: 'One number',
    valid: hasNumber,
  },
  {
    message: `${MIN_PASSWORD_LENGTH} characters minimum`,
    valid: isLongEnough,
  },
];

const getRuleIconStateProps = (valid: boolean) => {
  if (valid) {
    return {
      class: 'kogPlatformGreenDarken10',
      iconClass: 'fa-circle-check',
      size: 's',
    };
  }
  if (showRuleErrors.value && !valid) {
    return {
      class: 'kogPlatformRedDarken10',
      iconClass: 'fa-circle-x',
      size: 's',
    };
  }
  return {
    class: 'padd-xs',
    iconClass: 'fa-circle',
    size: 'xs',
  };
};

const numberOfRulesCompletedString = computed(() => {
  const numberOfRulesCompleted = validators.filter(validator => validator.valid.value).length;
  return `${numberOfRulesCompleted} of ${validators.length} rules completed`;
});

watch([password, passwordConfirmation], () => {
  if (
    password.value &&
    passwordConfirmation.value &&
    doPasswordsMatch.value &&
    validators.every(validator => validator.valid.value)
  ) {
    model.value = password.value;
  } else {
    model.value = undefined;
  }
});

watch(
  () => model.value,
  newPassword => {
    if (newPassword === '') {
      password.value = '';
      passwordConfirmation.value = '';
      showRuleErrors.value = false;
      showPasswordDoesNotMatchError.value = false;
    }
  },
);
</script>

<template>
  <div>
    <kog-input
      v-model:value="password"
      aria-describedby="password-rules"
      :label="enterPasswordLabel"
      type="password"
      @input="showRuleErrors = false"
      @blur="showRuleErrors = !!password"
    />
    <kog-input
      v-model:value="passwordConfirmation"
      class="margin-top-m"
      label="Repeat password"
      type="password"
      :is-invalid="showPasswordDoesNotMatchError && !doPasswordsMatch"
      validation-message="Passwords don't match"
      @input="showPasswordDoesNotMatchError = false"
      @blur="showPasswordDoesNotMatchError = !!passwordConfirmation"
    />

    <div class="margin-top-xs">Password rules</div>
    <div
      class="sr-only"
      aria-live="assertive"
    >
      {{ numberOfRulesCompletedString }}
    </div>
    <div
      id="password-rules"
      class="kog-row text-small muted margin-top-s"
    >
      <div
        v-for="validator in validators"
        :key="validator.message"
        class="kog-col-6"
      >
        <p class="sr-only">
          {{ validator.valid.value ? 'Completed' : 'Not completed' }} {{ validator.message }}
        </p>
        <div
          class="flexContainer flexContainer-alignCenter"
          aria-hidden="true"
        >
          <kog-icon
            theme="custom"
            fa-style="solid"
            is-non-actionable
            v-bind="getRuleIconStateProps(validator.valid.value)"
          />
          <p
            class="margin-0 margin-left-xxs"
            aria-hidden="true"
            :class="{
              'text-normal': !validator.valid.value,
              'text-bold': validator.valid.value,
              error: showRuleErrors && !validator.valid.value,
            }"
          >
            {{ validator.message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.SetPasswordInput--hiddenError {
  visibility: hidden;
}
</style>
