<script setup>
import KogButtonLoadingSpinner from 'sharedApp/components/base/buttons/kog-button-loading-spinner.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

import { defaultTheme, themes } from './shared.js';

defineProps({
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: defaultTheme,
    validator: value => themes.includes(value),
  },
});

defineEmits(['click']);
</script>

<template>
  <button
    v-if="theme === 'normal'"
    :disabled="disabled ? disabled : null"
    :aria-label="`Log in with ${label}`"
    class="SsoButton-button KogButtonLegacy"
    @click="$emit('click')"
  >
    <img
      :src="icon"
      alt=""
      class="SsoButton-logo"
    />
    <div class="SsoButton-label margin-right-xxl">
      <div class="flexContainer flexContainer-center">
        <kog-button-loading-spinner v-if="isLoading" />
        <div v-if="!isLoading">
          {{ label }}
        </div>
      </div>
    </div>
  </button>

  <kog-button
    v-if="theme === 'simple'"
    :disabled="disabled"
    class="width-full"
    :label="label"
    button-style="accent"
    :is-loading:="isLoading"
    @click="$emit('click')"
  />
</template>

<style scoped>
.SsoButton-button {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0;

  text-align: center;
}

.SsoButton-logo {
  width: 38px;
  height: 100%;
}

.SsoButton-label {
  flex-grow: 1;
  text-align: center;
}
</style>
